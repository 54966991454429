import { Menu } from "../stores/interfaces";
import { ROUTER_HIDDEN } from "@/config/config";
export function getFlatArr(menuList: Menu.MenuOptions[]) {
  let newMenuList: Menu.MenuOptions[] = JSON.parse(JSON.stringify(menuList));
  return newMenuList.reduce((pre: Menu.MenuOptions[], current: Menu.MenuOptions) => {
    let flatArr = [...pre, current];
    if (current.children) flatArr = [...flatArr, ...getFlatArr(current.children)];
    return flatArr;
  }, []);
}

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export function isType(val: any) {
  if (val === null) return "null";
  if (typeof val !== "object") return typeof val;
  else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
}

/**
 * @description 获取当前时间对应的提示语
 * @return string
 */
export function getTimeState() {
  // 获取当前时间
  let timeNow = new Date();
  // 获取当前小时
  let hours = timeNow.getHours();
  // 判断当前时间段
  if (hours >= 6 && hours <= 10) return `早上好 ⛅`;
  if (hours >= 10 && hours <= 14) return `中午好 🌞`;
  if (hours >= 14 && hours <= 18) return `下午好 🌞`;
  if (hours >= 18 && hours <= 24) return `晚上好 🌛`;
  if (hours >= 0 && hours <= 6) return `凌晨好 🌛`;
}

/**
 * @description 递归找出所有面包屑存储到 pinia/vuex 中
 * @param {Array} menuList 所有菜单列表
 * @param {Object} result 输出的结果
 * @param {Array} parent 父级菜单
 * @returns object
 */
export const getAllBreadcrumbList = (
  menuList: Menu.MenuOptions[],
  result: { [key: string]: any } = {},
  parent = [],
) => {
  for (const item of menuList) {
    result[item.path] = [...parent, item];
    if (item.children) getAllBreadcrumbList(item.children, result, result[item.path]);
  }
  return result;
};
/**
 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
 * @param {String} prop 当前 prop
 * @return string
 * */
export function handleProp(prop: string) {
  const propArr = prop.split(".");
  if (propArr.length == 1) return prop;
  return propArr[propArr.length - 1];
}

/**
 * @description 服务端路由与本地定义路由不匹配的处理，已本地路由为主
 * @param {asyncMenu} prop 异步路由
 * @param {level} prop 路由层级
 * **/
export function handleMenuList(asyncMenu: Menu.MenuOptions[], level: number = 0) {
  level++;
  return asyncMenu.map((item) => {
    item.level = level;
    item.name = `${item.name}_${item.path}`;
    if (item.children && item.children.length) {
      let arr = item.children.filter((item) => !item.hidden);
      if (arr.length) item.redirect = `${arr[0]["path"]}`;
      handleMenuList(item.children, level);
    }
    return item;
  });
}

/**
 * @description async菜单树结构转换成扁平化
 * **/
export const handleMenuToArr = (tree: Menu.MenuOptions[], arr: Array<any> = []) => {
  for (const node of tree) {
    arr.push(node);
    if (node.children && node.children.length > 0) {
      handleMenuToArr(node.children, arr);
    }
  }
  return arr;
};
