import { Result, ResultData } from "@/api/interface/index";
import { Login } from "@/api/interface/login";
import { PORT1 } from "@/api/config/servicePort";
import DynamicRouter from "@/assets/json/dynamicRouter.json";
import AuthButtons from "@/assets/json/authButtons.json";
import qs from "qs";
import http from "@/api";
import { Menu } from "@/stores/interfaces";

/**
 * @name 登录模块
 */
// * 用户登录
interface ResultLogin {
  token: string;
}
export const loginApi = (params: Login.ReqLoginForm) => {
  return http.post<ResultData<ResultLogin>>(PORT1 + `/login`, params, { notifyWhenFailure: true });

  return http.post<Login.ResLoginList>(PORT1 + `/sysUser/login`, params, {
    headers: { noLoading: true },
  }); // 正常 post json 请求  ==>  application/json
  return http.post<Login.ResLoginList>(PORT1 + `/login`, params, { headers: { noLoading: true } }); // 控制当前请求不显示 loading
  return http.post<Login.ResLoginList>(PORT1 + `/login`, {}, { params }); // post 请求携带 query 参数  ==>  ?username=admin&password=123456
  return http.post<Login.ResLoginList>(PORT1 + `/login`, qs.stringify(params)); // post 请求携带表单参数  ==>  application/x-www-form-urlencoded
  return http.get<Login.ResLoginList>(
    PORT1 + `/login?${qs.stringify(params, { arrayFormat: "repeat" })}`
  ); // 如果是 get 请求可以携带数组等复杂参数
};
// * 获取验证码
export const getCode = () => {
  return http.get<ResultData<Login.ResCodeResult>>(
    PORT1 + `/captchaImage`,
    {},
    { headers: { noLoading: true, isToken: "true" } }
  ); // 正常 post json 请求  ==>  application/json
};

// * 获取按钮权限
export const getAuthButtonListApi = () => {
  //   return http.get<Login.ResAuthButtons>(
  //     PORT1 + `/auth/buttons`,
  //     {},
  //     { headers: { noLoading: true } }
  //   );
  // 如果想让按钮权限变为本地数据，注释上一行代码，并引入本地 authButtons.json 数据
  return AuthButtons;
};

// * 获取本地动态菜单列表
export const getAuthMenuListLocal = () => {
  // 如果想让菜单变为本地数据，注释上一行代码，并引入本地 dynamicRouter.json 数据
  return DynamicRouter as any;
};
// * 获取服务器动态菜单列表
export const getAuthMenuListApi = () => {
  return http.get<ResultData<Menu.MenuOptions[]>>(PORT1 + `/getRouters`);
};
// * 获取当前用户信息
export const getCurrentUserInfo = () => {
  return http.get<Menu.MenuOptions[]>(PORT1 + `/perms/getInfo`);
};


// * 用户退出登录
export const logoutApi = () => {
  return http.post(PORT1 + `/logout`);
};
