import Vue from "vue";
import { createI18n } from "vue-i18n";
import customZhCn from "./modules/zh";
import customZhUs from "./modules/en";
// import zhCnLocale from "view-design/dist/locale/zh-CN";
// import enUsLocale from "view-design/dist/locale/en-US";

const messages = {
  "zh-CN": { ...customZhCn },
  "en-US": { ...customZhUs },
};

// 自动根据浏览器系统语言设置语言
const navLang = navigator.language;
const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
let lang = localLang || localStorage.getItem("local") || "zh-CN";

const i18n = createI18n({
  locale: 'en-US',
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册“$t”方法
  allowComposition: true,
  messages,
});

export default i18n;
