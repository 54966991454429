import { Client } from "./client";
import { keepAliveInterval, qos, Theme, timeout } from "./config";
import type { Options } from "./interfaces";

const host = window.location.host;
const protocol = window.location.protocol == "http:" ? "ws://" : "wss://";
const MQTT_SERVICE =
  import.meta.env.MODE == "production"
    ? protocol + host + "/wssocket/ws"
    : import.meta.env.VITE_APP_BASE_MQTT;
const { VITE_APP_BASE_MQTT_USERNAME, VITE_APP_BASE_MQTT_PASSWORD } = import.meta.env;

let option: Options = {
  url: MQTT_SERVICE,
  qos: qos,
  theme: Object.values(Theme),
  option: {
    userName: VITE_APP_BASE_MQTT_USERNAME,
    password: VITE_APP_BASE_MQTT_PASSWORD,
    keepAliveInterval,
    timeout,
  },
};
/**
 * 实例化MQ对象
 */
export default Client.getInstance(option);
/**
 * 参数和主题暴露
 */
export { Options, Theme };
