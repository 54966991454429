/*
 * @Author: xiongyi xiongyi@teamway.org.cn
 * @Date: 2024-08-17 17:49:14
 * @LastEditors: xiongyi xiongyi@teamway.org.cn
 * @LastEditTime: 2024-11-25 11:45:59
 * @FilePath: \business-web\src\directives\modules\alg.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { VNode, Directive, DirectiveBinding } from "vue";

export const alg: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const { value } = binding;
    removeDom(el, value);
  },
  updated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const { value } = binding;
    // console.log("v-alg binding.value:", value);
    removeDom(el, value);
  },
};

function removeDom(el: HTMLElement, value: any) {
  if (value.isHidden) {
    el.style.display = "none";
  } else {
    el.style.display = "flex"; // 用户有可能不先选算法类型而先选过滤类型
    // 若选了算法类型：1-智能巡视 2-智能检测 3-红外测温 4-智能输煤
    if (value.algType === "1") {
      // console.log("智能巡视");
      // 智能巡视类型的算法不展示的表单项
      const hiddenFormItemList = [
        "level",
        // "imageCount",
        "countEachSecond",
        "reportTime",
        "reportEffectWorkTime",
        "removal",
      ];
      if (hiddenFormItemList.includes(value.formItem)) {
        // el.remove();
        el.style.display = "none";
      } else {
        el.style.display = "flex";
      }
    } else if (value.algType === "2" || value.algType === "4") {
      // console.log("智能监测或智能输煤");
      // 智能检测或智能输煤类型的算法不展示的表单项
      // const hiddenFormItemList = ["continuousFrame"];
      const hiddenFormItemList = ["waitTime"];
      if (hiddenFormItemList.includes(value.formItem)) {
        el.style.display = "none";
      } else {
        el.style.display = "flex";
      }
    } else if (value.algType === "3") {
      // console.log("红外测温");
      // 红外测温类型的算法只展示算法名称(后面改成了算法标识)、算法描述(后面改成了算法名称)、算法类型、等待时间4个表单项
      if (value.formItem !== "waitTime") {
        el.style.display = "none";
      } else {
        el.style.display = "flex";
      }
    }
  }
}
