/*
 * @Author: xiongyi xiongyi@teamway.org.cn
 * @Date: 2024-08-15 20:54:27
 * @LastEditors: xiongyi xiongyi@teamway.org.cn
 * @LastEditTime: 2024-11-08 11:15:18
 * @FilePath: \business-web\src\api\modules\base.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "@/api";
import { ResPage, Result, ResultData, ReqPage } from "@/api/interface";
import { PORT2, PORT3, PORT7 } from "../config/servicePort";
import {
  BaseAlg,
  BaseAlgArg,
  BaseCamera,
  BaseDecoder,
  BaseInformation,
  BaseRegion,
  BaseStream,
  BaseAccess,
  BaseAlgCheckPoint,
  BaseRule,
  BaseModel,
  BaseUsage,
  ProjectTypeCount,
  environmentalWarning
} from "../interface/base";
import { exportAxios } from "@/api/export";
/**
 * @name 摄像机配置
 * **/
//查询摄像机
export const listCamera = (data: Partial<BaseCamera.CameraParams>) => {
  return http.post<ResPage<BaseCamera.CameraList>>(PORT2 + "/camera/selectByCondition", data);
};
//查询所有摄像机
export const getAllCamera = () => {
  return http.get<ResPage<BaseCamera.CameraList>>(PORT2 + "/camera/getAll");
};
//新增
export const addCamera = (data: BaseCamera.CameraParams) => {
  return http.post<Result>(PORT2 + "/camera/add", data);
};
//修改
export const updateCamera = (data: BaseCamera.CameraParams) => {
  return http.post<Result>(PORT2 + "/camera/update", data);
};
//删除
export const delCamera = (ids: string[]) => {
  return http.post<Result>(PORT2 + "/camera/batchDel", { ids });
};
//刷新状态
export const reflushCamera = () => {
  return http.post<Result>(
    PORT2 + "/camera/reflushStatus",
    {},
    { loading: true, notifyWhenFailure: true, notifyWhenSuccess: true },
  );
};
//抓图
export const snapshotCamera = (data: BaseCamera.SnapshotParams) => {
  return http.post<ResultData<string>>(PORT2 + "/camera/snapshot", data, {
    loading: true,
    notifyWhenFailure: true,
    notifyWhenSuccess: true,
  });
};
//导出
export const exportCamera = (data: BaseCamera.CameraParams) => {
  return exportAxios(PORT2 + "/camera/export", data);
};
//导入
export const importCamera = (data: any) => {
  return http.post<ResultData<BaseCamera.CameraImportData>>(PORT2 + "/camera/import", data, {
    loading: true,
  });
  // return exportAxios(PORT2 + "/camera/import", data);
};
//下载
export const downloadCamera = () => {
  return exportAxios(PORT2 + "/camera/downloadTemplate");
};

//批量修改
export const updateCameraBatch = (data: Partial<BaseCamera.CameraList>) => {
  return http.post<Result>(PORT2 + "/camera/batchUpdate", data);
};
//批量新增
export const addCameraBatch = (cameraList: Array<BaseCamera.CameraList>) => {
  return http.post<Result>(PORT2 + "/camera/batchAdd", { cameraList });
};
//检索新设备
export const searchDevice = () => {
  return http.get<ResPage<BaseCamera.DeviceList>>(
    PORT3 + "/deviceInfo/getAllDeviceInfo",
    {},
    {
      headers: {
        loading: true,
      },
    },
  );
};
//根据cameraId查询摄像机信息
export const listCameraQueryId = (id: string) => {
  return http.get<ResPage<BaseCamera.CameraList>>(PORT2 + `/camera/getCameraAndTypeById/${id}`);
};
//selectAllDevice

/**
 * @name 区域配置
 * **/

/**
 * 查询区域树
 */
export const listRegion = () => {
  return http.get<ResultData<BaseRegion.RegionList[]>>(PORT2 + "/region/findTree");
};

/**
 * 添加区域
 */
export const addRegion = (data: Partial<BaseRegion.RegionList>) => {
  return http.post<Result>(PORT2 + "/region/add", data);
};
/**
 * 修改区域
 */
export const updateRegion = (data: Partial<BaseRegion.RegionList>) => {
  return http.post<Result>(PORT2 + "/region/update", data);
};

/**
 * 批量删除
 */
export const delRegion = (ids: string[]) => {
  return http.post<Result>(PORT2 + "/region/batchDel", { ids });
};

/**
 * @name 流媒体配置
 * **/
// 查询所有流媒体信息
export const listStream = () => {
  return http.post<ResultData<BaseStream.StreamList[]>>(PORT2 + "/streamInfo/getStreamInfos");
};
// 根据ID查询流媒体信息
// id  1-流媒体信息 2-目标识别信息配置
export const apiGetStreamInfoById = (id: "1" | "2") => {
  return http.get<ResultData<BaseStream.StreamList>>(PORT2 + `/streamInfo/getStreamInfoById/${id}`);
};
//修改流媒体
export const updateStream = (data: BaseStream.StreamList) => {
  return http.post<ResPage<BaseStream.StreamList>>(PORT2 + "/streamInfo/update", data);
};

/**
 * @name 模型配置
 * **/
// 新增模型
export const apiAddModel = (data: SetOptional<BaseModel.IModelInfo, "id">) => {
  return http.post<Result>(PORT2 + "/model/add", data);
};
// 修改模型
export const apiUpdateModel = (data: BaseModel.IModelInfo) => {
  return http.post<Result>(PORT2 + "/model/update", data);
};
// 删除模型
export const apiRemoveModel = (data: { ids: string[] }) => {
  return http.post<Result>(PORT2 + "/model/batchDel", data);
};
// 分页查询模型
export const apiGetModelList = (data: {
  modelUrl?: string;
  algFrameType?: number;
  types?: string;
  graphCardId?: string;
  pageSize: number;
  pageIndex: number;
}) => {
  return http.get<ResPage<BaseModel.IModelInfo[]>>(PORT2 + "/model/selectPageList", data);
};
// 查询所有模型
export const apiGetModelListAll = () => {
  return http.get<ResultData<BaseModel.IModelInfo[]>>(PORT2 + "/model/getAll");
};
// 获取国标推流汇总参数
export const apiGetGBParameter = (data: { algId: string; cameraId: string }) => {
  return http.get<ResultData<BaseModel.IGBParameterInfo>>(PORT2 + "/model/getParameter", data, {
    notifyWhenFailure: true,
    notifyWhenSuccess: false,
  });
};
// 开始推流（通过国标信令服务器取流）
export const apiStartStreamByGB28181 = (data: BaseModel.IGBParameterInfo) => {
  return http.post<ResultData<BaseModel.IStreamUrlInfo>>(
    PORT7 + "/objectDetect/startStreamByGB28181",
    data,
    {
      notifyWhenFailure: true,
      notifyWhenSuccess: false,
    },
  );
};
// 停止推流
export const apiStopStreamByGB28181 = (data: { token: string }) => {
  return http.get<Result>(PORT7 + "/objectDetect/stopStream", data, {
    notifyWhenFailure: false,
    notifyWhenSuccess: false,
  });
};

/**
 * @name 解码器配置
 * **/
//解码器查询
export const listDecoder = () => {
  return http.get<ResPage<BaseDecoder.DecoderList>>(PORT2 + "/decoder/get");
};
//解码器新增

export const addDecoder = (data: BaseDecoder.DecoderList) => {
  return http.post<Result>(PORT2 + "/decoder/add", data);
};

//解码器修改

export const updateDecoder = (data: BaseDecoder.DecoderList) => {
  return http.post<Result>(PORT2 + "/decoder/update", data);
};
//解码器删除
export const delDecoder = (decoderIds: string[]) => {
  return http.post<Result>(PORT2 + "/decoder/batchDel", { decoderIds });
};

/**
 * @name 算法配置
 * **/
//查询算法配置
export const listAlg = (data: BaseAlg.AlgParams) => {
  return http.post<ResPage<BaseAlg.AlgList>>(PORT2 + "/algorithmInfo/selectPageList", data);
};
//新增算法配置
export const addAlg = (data: BaseAlg.AlgParams) => {
  return http.post<Result>(PORT2 + "/algorithmInfo/add", data);
};
//修改算法配置
export const updateAlg = (data: BaseAlg.AlgParams) => {
  return http.post<Result>(PORT2 + "/algorithmInfo/update", data);
};
//删除算法配置
export const delAlg = (ids: string[]) => {
  return http.post<Result>(PORT2 + "/algorithmInfo/batchDel", { ids });
};
//根据flag查询算法列表
export const getAlg = (flag: BaseCamera.FeatureEnum) => {
  return http.get<ResPage<BaseAlg.AlgList>>(PORT2 + `/algorithmInfo/selectAllList/${flag}`);
};
//根据flag查询所有算法
export const getAllAlg = (flag: 1 | 2 | 3) => {
  return http.get<ResPage<BaseAlg.AlgList>>(PORT2 + `/algorithmInfo/selectAllList/${flag}`);
};

//查询告警条件
export const conditionAlg = (data: BaseAlg.AlgConditionPrams) => {
  return http.post<ResPage<BaseAlg.AlgConditionList>>(
    PORT2 + "/insAlarmConfig/selectCondition",
    data,
  );
};
//查询告警配置
export const configAlg = (data: BaseAlg.AlgConditionPrams) => {
  return http.post<ResultData<BaseAlg.AlgConfig>>(PORT2 + "/insAlarmConfig/selectList", data);
};
//新增/修改告警配置
export const addOrUpdate = (data: BaseAlg.AlgConfig) => {
  return http.post<Result>(PORT2 + "/insAlarmConfig/addOrUpdate", data, {
    notifyWhenFailure: true,
    notifyWhenSuccess: true,
  });
};

/**
 * @name 算法参数配置
 * **/
//获取模板算法列表
export const listAlgArgTemplate = () => {
  return http.get<ResultData<BaseAlgArg.AlgArgTemplateList>>(PORT2 + "/algorithm/list");
};

//查询算法告警参数配置
export const listAlgArg = (data: BaseAlgArg.AlgArgPrams) => {
  return http.post<ResultData<BaseAlgArg.AlgArgList>>(PORT2 + "/algorithm/getDeviceConfig", data);
};
//更新算法参数配置
export const updateAlgArg = (data: BaseAlgArg.ConfigInfoList) => {
  return http.post<Result>(PORT2 + "algorithm/updateConfig", data);
};
//更新算法参数配置
export const addAlgArg = (data: BaseAlgArg.DeviceInfoList) => {
  return http.post<Result>(PORT2 + "algorithm/add", data);
};
//删除算法参数配置
export const delAlgArg = (data: BaseAlgArg.AlgArgDelPrams) => {
  return http.post<Result>(PORT2 + "algorithm/remove", data);
};

/**
 * @name 系统资料配置
 * **/
//查询系统资料配置
export const listInformation = () => {
  return http.get<ResultData<BaseInformation.InformationList>>(
    PORT2 + "/sysConfig/selectSysConfig",
  );
};
//更新系统资料配置
export const updateInformation = (data: BaseInformation.InformationList) => {
  return http.post<ResultData<BaseAlgArg.AlgArgList>>(PORT2 + "/sysConfig/saveSysConfig", data, {
    notifyWhenFailure: true,
    notifyWhenSuccess: true,
  });
};

/**
 * @name 接入配置
 * **/
// 获取上级平台信息
export const getInfoFromHigherPlatform = (id: number) => {
  return http.get<ResultData<BaseAccess.PlatformInfoList>>(
    PORT2 + `/nationalStandard/getById/${id}`,
    // {},
    // {
    // notifyWhenFailure: true,
    // notifyWhenSuccess: false,
    // },
  );
};
// 修改上级平台信息
export const updateInfoFromHigherPlatform = (data: BaseAccess.PlatformInfoList) => {
  return http.post<ResultData<any>>(
    PORT2 + "/nationalStandard/update",
    data,
    // {
    // notifyWhenFailure: true,
    // notifyWhenSuccess: true,
    // }
  );
};
// 分页查询上级平台关联摄像机
export const getCameraRelationList = (data: ReqPage & { superiorPlatformId: number }) => {
  return http.post<ResultData<BaseAccess.CameraRelationList[]>>(
    PORT2 + "/superiorCameraRelation/selectPageList",
    data,
  );
};
// 批量修改上级平台关联摄像机
export const updateCameraRelationList = (data: BaseAccess.CameraRelationList) => {
  return http.post<ResultData<any>>(
    PORT2 + "/superiorCameraRelation/updateBatchById",
    data,
    // {
    //   notifyWhenFailure: true,
    //   notifyWhenSuccess: false,
    // }
  );
};
// 刷新（获取上级平台状态）
export const getStatusOfHigherPlatform = (id: number) => {
  return http.get<ResultData<any>>(
    PORT2 + `/nationalStandard/getStatus/${id}`,
    // {},
    // {
    //   notifyWhenFailure: true,
    //   notifyWhenSuccess: false,
    // },
  );
};
// 资源共享（向上级平台推送设备信息）
export const sendDeviceInfoToHigherPlatform = (id: number) => {
  return http.get<ResultData<any>>(
    PORT2 + `/nationalStandard/sendDeviceInfo/${id}`,
    {},
    {
      notifyWhenFailure: true,
      notifyWhenSuccess: true,
    },
  );
};

/**
 * @name 算法检测点配置
 * **/
// 查询检测点列表
export const getCheckPointList = (data: BaseAlgCheckPoint.CheckPointListPrams) => {
  return http.post<ResultData<BaseAlgCheckPoint.CheckPointList>>(
    PORT2 + "/checkPoint/listCheckPoint",
    data,
  );
};
// 新增检测点
export const addAlgCheckPoint = (data: BaseAlgCheckPoint.AddCheckPointPrams) => {
  return http.post<ResultData<BaseAlgCheckPoint.CheckPointList>>(
    PORT2 + "/checkPoint/saveCheck",
    data,
  );
};
// 根据摄像机id查询算法列表
export const getAlgNameByCameraId = (cameraId: string) => {
  return http.get<ResultData<any>>(PORT2 + `/checkPoint/queryAlgNameByCameraId/${cameraId}`);
};
// 根据模板id获取算法模板
export const getAlgConfigInfoByTemplateId = (templateId: string) => {
  return http.get<ResultData<any>>(PORT2 + `/algorithm/config/${templateId}`);
};
// 删除检测点配置
export const deleteAlgCheckPoint = (data: { configId: string[] }) => {
  return http.post<ResultData<any>>(PORT2 + "/checkPoint/deleteCheckPoint", data);
};
// 修改检测点配置
export const updateAlgCheckPoint = (data: BaseAlgCheckPoint.UpdateCheckPointPrams) => {
  return http.post<ResultData<any>>(PORT2 + "/checkPoint/updateCheckPoint", data);
};
// 抓图测试接口(带用户名、密码的url转成可以访问的图片url)
export const captureTest = (data: { url: string }) => {
  return http.post<ResultData<any>>(PORT2 + "/checkPoint/urlChangeBase", data);
};

/**
 * @name 巡检规则配置
 * **/

//查询所有
export const listBaseRule = (data: BaseRule.RuleParams) => {
  return http.post<ResPage<BaseRule.RuleList>>(PORT2 + `/timeRule/selectNameList`, data);
};
//查询详情
export const getBaseRule = (id: string) => {
  return http.get<ResultData<BaseRule.RuleList>>(PORT2 + `/timeRule/getById/${id}`);
};
//新增
export const addBaseRule = (data: BaseRule.RuleParams) => {
  return http.post<Result>(PORT2 + `/timeRule/add`, data);
};
//编辑
export const updateBaseRule = (data: BaseRule.RuleParams) => {
  return http.post<Result>(PORT2 + `/timeRule/update`, data);
};
//删除
export const delBaseRule = (ids: string[]) => {
  return http.post<Result>(PORT2 + `/timeRule/batchDel`, { ids });
};

/**
 * @name 项目信息
 * **/
//基本信息
//查询所有
export const listBaseUsageInfo = (name: string) => {
  return http.post<ResPage<BaseUsage.BaseUsageInfo>>(PORT2 + `/project/projectInfoList`, {
    pageIndex: 1,
    pageSize: 1000,
    name,
  });
};
//查询详情
export const getBaseUsageInfo = (id: string) => {
  return http.get<ResultData<BaseUsage.BaseUsageInfo>>(PORT2 + `/project/project/${id}`);
};
//新增
export const addBaseUsageInfo = (data: BaseUsage.BaseUsageInfo) => {
  return http.post<Result>(PORT2 + `/project/saveProject`, data, { notifyWhenSuccess: true });
};
//编辑
export const updateBaseUsageInfo = (data: BaseUsage.BaseUsageInfo) => {
  return http.post<Result>(PORT2 + `/project/updateProject`, data, { notifyWhenSuccess: true });
};
//删除
export const delBaseUsageInfo = (ids: string[]) => {
  return http.post<Result>(PORT2 + `/project/batchDel`, { ids });
};
// 项目进度
export const getProjectProgressList = () => {
  return http.get<ResultData<BaseUsage.BaseUsageProjectProgressList[]>>(
    PORT2 + `/project/progress`,
    {},
    { notifyWhenFailure: true },
  );
};



// 统计项目类型数量
export const getProjectTypeNum = () => {
  return http.get<ResultData<Array<ProjectTypeCount>>>(
    PORT2 + "/project/count",
    {},
    { notifyWhenFailure: true },
  );
};

//项目部信息
//查询详情
export const getBaseUsageProject = (id: string) => {
  return http.get<ResultData<BaseUsage.BaseUsageProject[]>>(PORT2 + `/project/getSection/${id}`);
};
//新增
export const addBaseUsageProject = (data: BaseUsage.BaseUsageProject) => {
  return http.post<Result>(PORT2 + `/project/saveSection`, data);
};
//编辑
export const updateBaseUsageProject = (data: BaseUsage.BaseUsageProject) => {
  return http.post<Result>(PORT2 + `/project/updateSection`, data);
};

//参建方
//查询详情
export const getBaseUsageProjectPart = (id: string) => {
  return http.get<ResultData<BaseUsage.BaseUsageProjectPart[]>>(PORT2 + `/project/part/${id}`);
};
//新增
export const addBaseUsageProjectPart = (data: BaseUsage.BaseUsageProjectPartForm) => {
  return http.post<Result>(PORT2 + `/project/savePart`, data);
};
//新增
export const updateBaseUsageProjectPart = (data: BaseUsage.BaseUsageProjectPartForm) => {
  return http.post<Result>(PORT2 + `/project/updatePart`, data);
};
//删除
export const delBaseUsageProjectPart = (id: string) => {
  return http.post<Result>(PORT2 + `/project/delPart/${id}`);
};

/**
 * @name 环境告警配置
 * **/
// 查询配置信息
export const getConfig = (data:environmentalWarning.getConfigParams) => {
  return http.post<ResultData<environmentalWarning.Datum>>(PORT2 + `/envAlarmConfig/getConfig`, data);
};
// 新增或更新告警配置
export const editOrSaveConf = (data:environmentalWarning.envAlarmParams) => {
  return http.post<Result>(PORT2 + `/envAlarmConfig/editOrSaveConf`, data);
};
