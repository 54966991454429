import type { AxiosError, AxiosResponse } from "axios";
import type { Result, RequestConfig } from "@/api/interface";
import { ElMessage } from "element-plus";
import { ResultEnum, notifyType } from "@/enums/httpEnum";
import router from "@/router";
import { checkStatus } from "./checkStatus";
/**
 * @description  判断全局提示接口,优先级为 (notifyWhenFailure/notifyWhenSuccess) > isNotify
 * **/
export default {
  response: {
    onFulfilled: (response: AxiosResponse<Result>) => {
      const { code } = response.data;
      const { throwError } = response.config as RequestConfig;
      // 对code的响应做反馈
      switch (code) {
        case ResultEnum.SUCCESS:
          return sucessFull(response);
        case ResultEnum.OVERDUE:
          return throwError === false ? response : Promise.reject(response);
        case ResultEnum.EXCEED:
          ElMessage.error(`当前用户无权限操作此功能`);
          return throwError === false ? response : Promise.reject(response);
        case undefined:
          return throwError === false ? response : Promise.reject(response);
        default:
          return errorFull(response);
      }
    },
    onRejected: (error: AxiosError<Result>) => {
      const { response } = error;
      // 请求超时 && 网络错误单独判断，没有 response
      ElMessage.closeAll();
      if (error.message.indexOf("timeout") !== -1) ElMessage.error("请求超时！请您稍后重试");
      if (error.message.indexOf("Network Error") !== -1) ElMessage.error("网络错误！请您稍后重试");
      // 根据响应的错误状态码，做不同的处理
      if (response) checkStatus(response.status);
      // 服务器结果都没有返回(可能服务器错误可能客户端断网)，断网处理:可以跳转到断网页面
      if (!window.navigator.onLine) router.replace("/500");
      return Promise.reject(error);
    },
  },
};

const isNotify = (url: string | undefined) => {
  if (typeof url === "string") return notifyType.find((item) => url.includes(item)) ? true : false;
  else return false;
};

//判断成功时的是否弹框提示
const sucessFull = (response: AxiosResponse<Result>) => {
  const { data } = response;
  const { notify, notifyWhenSuccess, url } = response.config as RequestConfig;
  if (
    notify ||
    notifyWhenSuccess ||
    (typeof notify == "undefined" && typeof notifyWhenSuccess == "undefined" && isNotify(url))
  ) {
    ElMessage.closeAll();
    ElMessage.success(`${data.message || ""}`);
  }
  return response;
};

//判断失败时是否弹框提示
const errorFull = (response: AxiosResponse<Result>) => {
  const { data } = response;
  const { notify, notifyWhenFailure, throwError, url } = response.config as RequestConfig;
  if (
    notify ||
    typeof notifyWhenFailure == "undefined" ||
    notifyWhenFailure ||
    (typeof notify == "undefined" && typeof notifyWhenFailure == "undefined" && isNotify(url))
  ) {
    ElMessage.error(`${data.message || ""}`);
    return throwError === false ? response : Promise.reject(response);
  }
  return response;
};
