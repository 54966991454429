<script setup lang="ts">
import { isRef, onMounted, reactive, ref, shallowRef, toRef, toRefs, unref, useModel } from "vue";
import { useTheme } from "./hooks/useTheme";
import { MqttStore } from "@/stores/modules/mqtt";
import { useDictStore } from "@/stores/modules/dictionary";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import Test from '@/components/TyAiLabel/test.vue'
import autofit from 'autofit.js'
const locale = ref(zhCn);
const useMqtt = MqttStore();
const dictStore = useDictStore();
dictStore.initDict();
const { initTheme } = useTheme();
const screenWidth = ref(window.innerWidth); // 存储屏幕宽度
// 更新屏幕宽度的函数
const updateScreenWidth = () => {
    screenWidth.value = window.innerWidth;
    console.log(screenWidth.value, 'screenWidth1122')
  };
onMounted(() => {
  initTheme();
  useMqtt.mqttMessage();
  console.log(window.chrome, '1122')
  console.log(screenWidth.value, 'screenWidth1122')
  window.addEventListener('resize', updateScreenWidth);
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('android') || userAgent.includes('ios')) {
    console.log('可能是移动设备投屏1122');
  } else if (userAgent.includes('cast') || userAgent.includes('airplay')) {
    console.log('可能是通过投屏设备1122');
  } else {
    console.log('可能是电脑页面1122');
  }
  if (document.fullscreenElement) {
    console.log('可能是投屏页面，因为正在全屏1122');
  } else {
    console.log('可能是电脑页面1122');
  }
  const screenWidths = screen.width;
  const screenHeights = screen.height;
  console.log(screenWidths, 'screenWidths1122')
  console.log(screenHeights, 'screenHeights1122')
if (screenWidths > 1920 && screenHeights > 1080) {
  console.log('可能是投屏页面1122');
} else {
  console.log('可能是电脑页面1122');
}
navigator.mediaDevices.enumerateDevices()
  .then(devices => {
    const screenSharingDevice = devices.find(device => device.kind === 'videoinput' && device.label.includes('screen'));
    if (screenSharingDevice) {
      console.log('页面可能正在投屏，因为发现屏幕共享设备1122');
    } else {
      console.log('没有发现屏幕共享设备，可能不是投屏1122');
    }
  })
  .catch(error => console.error('无法检测设备:1122', error));
    if (window.chrome && window.chrome.cast) {
      console.log('页面可能正在通过 Chrome Cast 投屏1122');
    } else {
      console.log('未检测到投屏功能1122');
    }
    autofit.init({
        dh: screenHeights,
        dw: screenWidths,
        el:"body",
        resize: true,
        limit:0.3
    })
});
</script>
<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>

<style>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
