import { defineStore } from "pinia";
import { Dictionary } from "@/stores/interfaces/index"

// 静态字典
const staticDict = Object.freeze({
  // 摄像机状态
  cameraState: [
    { id: "1", value: "1", label: "在线", color: "#00BAAB" },
    { id: "0", value: "0", label: "离线", color: "#909399" },
  ],
  // 摄像机类型
  cameraType: [
    { id: "1", value: "1", label: "枪机" },
    { id: "2", value: "2", label: "球机" },
    { id: "3", value: "3", label: "云台" },
    { id: "4", value: "4", label: "半球" },
    { id: "5", value: "5", label: "卡片机" },
  ],
  // 摄像机厂家
  cameraFactory: [
    { id: "1", value: "1", label: "海康" },
    { id: "2", value: "2", label: "大华" },
    { id: "3", value: "3", label: "华为" },
    { id: "4", value: "4", label: "高德" },
    { id: "5", value: "5", label: "金三立" },
  ],
  // 摄像机功能
  cameraFeature: [
    { id: "1", value: "1", label: "普通摄像机", simpleLabel: "普通" },
    { id: "2", value: "2", label: "红外摄像机", simpleLabel: "红外" },
    { id: "3", value: "3", label: "水位摄像机", simpleLabel: "水位" },
    { id: "4", value: "4", label: "人脸摄像机", simpleLabel: "人脸" },
  ],
  // 视频播放协议
  videoProtocol: [
    { id: "0", value: "0", label: "udp" },
    { id: "1", value: "1", label: "tcp" },
  ],
  // 告警状态
  alarmState: [
    { id: "0", value: "0", label: "未处理", color: '#F5A738' },
    { id: "1", value: "1", label: "已处理", color: '#19c199' },
    { id: "2", value: "2", label: "误报", color: '#F56C6C' },
  ],
  // 告警等级
  alarmLevel: [
    { id: "1", value: "1", label: "一般告警", color: '#FFC746' },
    { id: "2", value: "2", label: "重要告警", color: '#BFC3FF' },
    { id: "3", value: "3", label: "紧急告警", color: '#F15151' },
  ],
  // 告警类型
  alarmType: [
    { id: "1", value: "1", label: "环境告警" },
    { id: "2", value: "2", label: "消防告警" },
    { id: "3", value: "3", label: "安防告警" },
    { id: "4", value: "4", label: "巡检告警" },
    { id: "5", value: "5", label: "人员安全告警" },
    { id: "6", value: "6", label: "视频告警" },
    { id: "7", value: "7", label: "机器人告警" },
  ],
  // 性别
  sex: [
    { id: "1", value: "1", label: "男" },
    { id: "2", value: "2", label: "女" },
  ],
  // 人员身份类型
  personIdentityType: [
    { id: "1", value: "1", label: "业主" },
    { id: "2", value: "2", label: "监理" },
    { id: "3", value: "3", label: "总包" },
    { id: "4", value: "4", label: "施工人员" },
  ],
  // 人员证件类型
  personCertificateType: [
    { id: "111", value: "111", label: "身份证" },
    { id: "990", value: "990", label: "其他" },
  ],
  // 人员工种
  personJobType: [
    { id: "1", value: "1", label: "焊接工" },
    { id: "2", value: "2", label: "安装工" },
    { id: "3", value: "3", label: "塔吊操作工" },
    { id: "4", value: "4", label: "机修工" },
    { id: "5", value: "5", label: "土建工" },
    { id: "6", value: "6", label: "普工" },
    { id: "7", value: "7", label: "普通员工" },
  ],
  // 人员卡片类型
  personCardType: [
    { id: "1", value: "1", label: "普通卡" },
    { id: "2", value: "2", label: "临时卡" },
  ],
  // 安防设备类型
  securityDeviceType: [
    { id: "1", value: "1", label: "门禁" },
    { id: "2", value: "2", label: "道闸" },
    { id: "3", value: "3", label: "超速设备" },
  ],
  // 安防设备生产厂家
  securityDeviceFacturer: [
    { id: "1", value: "1", label: "海康" },
    { id: "2", value: "2", label: "大华" },
  ],
  // 算法框架种类
  algFrameType: [
    { id: 1, value: 1, label: "yolov5" },
    { id: 2, value: 2, label: "yolov8" },
  ]
});

type TStaticDict = typeof staticDict

// 字典
export const useDictStore = defineStore({
  id: "DictState",
  state: (): {
    dictList: TStaticDict;
    dictMapper: any;
  } => ({
    dictList: staticDict,
    // map优化，减少遍历
    dictMapper: {},
  }),
  getters: {
    /**
     * 获取字典map
     */
    getDictMapper: (state) => {
      return (...args: any) => {
        let temp = state.dictMapper;
        return args.reduce((temp: any, arg: any) => {
          return temp?.[arg] || undefined;
        }, temp);
      };
    },
    /**
     * 获取字典==>list, 不带全部选项
     * @param dictName  字典名称
     */
    getDictList: (state) => {
      return (dictName: keyof TStaticDict) => {
        return state.dictList[dictName] || [];
      };
    },
    /**
     * 获取字典==>list, 带全部选项
     * @param dictName  字典名称
     */
    getDictListWithAll: (state) => {
      return (dictName: keyof TStaticDict) => {
        const arr: Array<any> = [{ id: "", value: "", label: "全部" }]
        return arr.concat(
          state.dictList[dictName] || []
        );
      };
    },
  },
  actions: {
    // 初始化字典
    initDict() {
      // 处理字典list
      this.dictList = staticDict;
      // 处理字典map
      (Object.keys(staticDict)).forEach((key) => {
        // @ts-ignore
        staticDict[key].forEach((item: any) => {
          if(!this.dictMapper[key]) {
            this.dictMapper[key] = {}
          }
          this.dictMapper[key][String(item.id)] = item;
        });
      });
    },
  },
});
