// * 请求枚举配置
/**
 * @description：请求配置
 */
export enum ResultEnum {
  SUCCESS = 0,
  ERROR = 500,
  OVERDUE = 401, //  token过期
  EXCEED = 403, //无权限
  TIMEOUT = 30000,
  TYPE = "success",
}

/**
 * @description：请求方法
 */
export enum RequestEnum {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
  REQUEST = "REQUEST",
}

/**
 * @description：常用的contentTyp类型
 */
export enum ContentTypeEnum {
  // json
  JSON = "application/json;charset=UTF-8",
  // text
  TEXT = "text/plain;charset=UTF-8",
  // form-data 一般配合qs
  FORM_URLENCODED = "application/x-www-form-urlencoded;charset=UTF-8",
  // form-data 上传
  FORM_DATA = "multipart/form-data;charset=UTF-8",
}

/**
 * @description 配置url全局提示,不包含一下参数可单独配置
 * **/
export const notifyType = [
  "update",
  "add",
  "remove",
  "batchAdd",
  "batchUpdate",
  "batchDel",
  "del",
  "save",
  "stop",
  "start",
];
