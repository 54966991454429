/**
 * @description MQTT配置文件
 */

/**
 * 心跳监测
 */
export const keepAliveInterval: number = 30;

/**
 * 连接超时
 */
export const timeout: number = 30;
/**
 * 通信质量
 * 0 最多一次；可能丢失；
 * 1 至少一次；可能重复
 * 2 恰好一次；不丢失不重复
 */
export const qos: 0 | 1 | 2 = 1;
/**
 * 主题枚举
 */
export enum Theme {
  /**
   * @description: 巡检结果进度
   *
   */
  insResultProgress = "/insResult/resultDetail", //手动巡检的进度
  insResultTask = "/insResult/result", //巡检任务进度
  insResultProgress1 = "/+/ins/insResultPointState1",
  /**
   * 录像下载相关
   */
  recordingUrl = "/gb28181/download/url", // 录像下载地址
  recordingDownloadProgress = "/gb28181/download/status", // 录像下载进度
  insPointConfig = "/ai/report/result",
  /**
   * 机器人控制
   * **/
  runningStatusData = "/robot/dynamic/runningStatusData", //机器人控制
  microclimateMeasData = "/robot/dynamic/microclimateMeasData", //机器人环境量
  /**
   * 算法告警上报
   * **/
  alarmData = "/alarmCenter/alarm/realTime",
  /**
   * 环境量
   * **/
  environmentData = "/statistics/environment",
}
