<template>
  <svg :class="svgClass" aria-hidden="true" v-bind="$attrs">
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script lang="ts">
export default {
  name: "TySVG",
};
</script>
<script lang="ts" setup>
import { computed } from "vue";
import "virtual:svg-icons-register";

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  className: {
    type: String,
  },
  size: {
    type: String,
  },
  color:{
    type:String,
    default:'var(--ty-text-color-secondary)'
  }
});
const iconName = computed(() => {
  let text = props.iconName.indexOf("icon-");
  return text == -1 ? `#icon-${props.iconName}` : `#${props.iconName}`;
});
const svgClass = computed(() => (props.className ? `#icon-${props.className}` : "TySVG-icon"));
const size = computed(() => {
  return `var(--ty-font-size-${props.size || "medium"})`;
});
const color=computed(()=>props.color)
</script>

<style scoped>
.TySVG-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  color: v-bind('color');
  overflow: hidden;
  /* margin-right: 5px; */
  font-size:v-bind('size');
  vertical-align: sub;
}
</style>
