import { createRouter, createWebHashHistory } from "vue-router";
import { LOGIN_URL } from "../config/config";
import { GlobalStore } from "../stores";
import { AuthStore } from "../stores/modules/auth";
import { staticRouter, errorRouter } from "./modules/staticRouter";
import { initDynamicRouter } from "@/router/modules/dynamicRouter";
/**
 * @description 动态路由参数配置简介
 * @param path==>菜单路径
 * @param name==>菜单名称
 * @param redirect ==>重定向地址
 * @param component ==>视图文件路径
 * @param meta ==>菜单信息
 * @param meta.icon ==>菜单图标
 * @param meta.title==>菜单标题
 * @param meta.activeMenu ==>当前路由为详情页时，需要高亮的菜单
 * @param meta.isLik ==>是否外链
 * @param meta.hidden  ==>是否隐藏
 * @param meta.isFull==>是否全屏(是否需要导航栏)
 * @param meta.isAffix ==>是否固定在tabs nav
 * @param meta.isKeepAlive ==>是否缓存
 * **/

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...staticRouter, ...errorRouter],
  strict: false,
  linkActiveClass: "router-link-active",
  linkExactActiveClass: "router-link-active",
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

/**
 * @description 路由拦截
 * **/
router.beforeEach(async (to, form, next) => {
  const globalStore = GlobalStore();
  const authStore = AuthStore();

  //动态设置标题
  const title = import.meta.env.VITE_GLOB_APP_TITLE;
  document.title = to.meta.title ? `${to.meta.title}` : title;
  authStore.setRouteName(to.path);

  //判断是否访问登录页，有token就在当前页，没有token充值路由并放行到登录页
  if (to.path.toLocaleLowerCase() === LOGIN_URL) {
    if (globalStore.token) return next(form.fullPath);
    resetRouter();
    return next();
  }

  // 5.判断是否有 Token，没有重定向到 login
  if (!globalStore.token) return next({ path: LOGIN_URL, replace: true });
  //动态路由表
  // 6.如果没有菜单列表，就重新请求菜单列表并添加动态路由
  if (!authStore.authMenuListGet.length) {
    await initDynamicRouter();
    return next({ ...to, replace: true });
  }
  // 7.正常访问页面
  next();
});

/**
 * @description 重置路由
 * */
export const resetRouter = () => {
  const authStore = AuthStore();

  authStore.flatMenuListGet.forEach((route: { name: any }) => {
    const { name } = route;
    if (name && router.hasRoute(name)) router.removeRoute(name);
  });
};

export default router;
