// ? 全局不动配置项 只做导出不做修改

// * 首页地址（默认）
export const HOME_URL: string = "";

// * 登录页地址（默认）
export const LOGIN_URL: string = "/login";

//无权限页面
export const TOKEN_EXCEED: string = "/403";


//约定公钥
export const PUBLICKEY: string =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC5aJsu7kKMfFXmgsfDZTNB7Xcn4QRkfaPGFICA9XE7vJmgQpK/UkM2KPLKeGyWikPJFJ2wb0/TUtMhTSuMMx0w2zvfwOpESsbJC7DdZAuCZWphq4I1zI5WP3RbXKozev23Rucm/9GLs+wEZY8sdt5WVM0eMUHmaYteo3aXp1p7nQIDAQAB";

// * 路由白名单地址（必须是本地存在的路由 staticRouter.ts）
export const ROUTER_WHITE_LIST: string[] = ["/500"];

// * 高德地图 key
export const AMAP_MAP_KEY: string = "3301e4a4c43d51536b963a36f2ab9525";

// * 百度地图 key
export const BAIDU_MAP_KEY: string = "";

// * 是否匹配后端动态路由，不匹配直接用前端路由
export const ROUTER_HIDDEN: boolean = true;
