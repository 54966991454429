import { h, watch, type VNode } from "vue";
import type { Binding } from "../interface";
import testImg from "@/assets/ui-image/empty.svg";
const createDom = (el: HTMLElement, binding: Binding, vnode: VNode) => {
  console.log(el, binding, vnode);

  let { value, arg } = binding;
  let text = arg || "暂无数据";
  if (!value || typeof value == "undefined" || (Array.isArray(value) && !value.length)) {
    el.innerHTML = `<div style="
          height: 100%;
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;">
          <img src="${testImg}" />
          <span style="
          display: inline-block;
          padding: 20px 0;
          color:#455b74">${text}</span>
        </div>`;
  } else {
    // 隐藏加载状态
    el.innerHTML = "";
  }
};
export const empty = {
  mounted(el: HTMLElement, binding: Binding, vnode: VNode) {
    createDom(el, binding, vnode);
  },
  updated(el: HTMLElement, binding: Binding, vnode: VNode) {
    createDom(el, binding, vnode);
  },
};
