import { defineStore } from "pinia";
import { mqttMessage } from "../interfaces/index";
import Client, { Theme } from "@/mqtt";

// MqttStore
export const MqttStore = defineStore({
  id: "MqttStore",
  state: () => {
    const initState: { [k: string]: any } = {};
    for (const key in Theme) {
      initState[key] = "";
    }
    return initState as { -readonly [P in keyof typeof Theme]: mqttMessage };
  },
  actions: {
    mqttMessage() {
      Client.client.on("message", (topic, messages,options) => {
        let message = messages instanceof Uint8Array ? JSON.parse(messages.toString()) : messages;
        const enumKey = getEnumKeyByEnumValue(Theme, topic);
        if (enumKey) {
          this[enumKey] = message;
        }
      });
    },
    pushMessage({}, data: { theme: string; message: Object }) {
      Client.client.publish(data.theme, JSON.stringify(data.message));
    },
  },
});

/**
 * @description: 通过枚举的值获取对应的key
 * @param {T} ResultEnum
 * @param {string} enumValue
 * @return {*}
 */
function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  ResultEnum: T,
  enumValue: string,
): keyof T {
  return Object.keys(ResultEnum).find((key) => ResultEnum[key] === enumValue) as keyof T;
}

export const marksData = {
  code: 0,
  eventPoint: [
    {
      region: [
        [218, 591],
        [674, 591],
        [674, 1079],
        [218, 1079],
      ],
      regionCode: "",
      result: "0.5",
      score: 0.9,
    },
  ],
  eventTime: "2024-08-27 02:46:02",
  imageUrl:
    "http://10.0.60.75/minio/test/20240827/1828262669527027712.jpg?versionId=87b420c7-6806-4b7d-83f4-5d790e6e5eb9",
  message: "SUCCESS",
  algName: "meter_reading",
  requestId: "1828262643513954304",
  version: 1.0,
};
