import { computed, onMounted, ref } from "vue";
import { getLightColor, getDarkColor } from "@/utils/theme/tool";
import { GlobalStore } from "@/stores";
import { DEFAULT_ThEME } from "@/config/colors";
import autofit from "autofit.js";
/**
 * @description 切换主题
 * */
export const useTheme = () => {
  const globalStore = GlobalStore();
  const themeConfig = computed(() => globalStore.themeConfig);

  const changeColor = (type: string, color: string) => {
    if (type == "primary") {
      globalStore.setThemeConfig({ ...themeConfig.value, primary: color });
    }
    // 获取计算后的样式
    let element = document.documentElement;

    // 获取CSS变量的值
    // 为了兼容暗黑模式下主题颜色也正常，以下方法计算主题颜色 由深到浅 的具体颜色
    element.style.setProperty(`--ty-color-${type}`, color);
    element.style.setProperty(
      `--ty-color-${type}-dark-2`,
      themeConfig.value.isDark == "ty-light"
        ? `${getLightColor(color, 0.2)}`
        : `${getDarkColor(color, 0.3)}`,
    );
    // 颜色加深或变浅
    for (let i = 1; i <= 9; i++) {
      element.style.setProperty(
        `--ty-color-${type}-light-${i}`,
        themeConfig.value.isDark == "ty-light"
          ? `${getLightColor(color, 1 - i / 10)}`
          : `${getDarkColor(color, i / 10)}`,
      );
    }
  };

  // 灰色和弱色切换
  const changeGreyOrWeak = (value: boolean, type: string) => {
    const body = document.body as HTMLElement;
    if (!value) return body.setAttribute("style", "");
    if (type === "grey") body.setAttribute("style", "filter: grayscale(1)");
    if (type === "weak") body.setAttribute("style", "filter: invert(80%)");
    let propName = type == "grey" ? "isWeak" : "isGrey";
    globalStore.setThemeConfig({ ...themeConfig.value, [propName]: false });
  };
  const changeAutofit = (value: boolean, init?: boolean) => {
    if (!init) {
      window.location.reload();
    } else {
      (autofit as any)?.init({
        designHeight: 1080,
        designWidth: 1920,
        renderDom: "#app",
        resize: true,
      });
    }
  };

  // 初始化 theme 配置
  const initTheme = () => {
    //iniTheme
    switchDark();
    let DEFAULT_ThEME_UPDATE: { [name: string]: string } = {
      ...DEFAULT_ThEME,
      primary: themeConfig.value.primary,
    };
    for (const key in DEFAULT_ThEME_UPDATE) {
      changeColor(key, DEFAULT_ThEME_UPDATE[key]);
    }
    if (themeConfig.value.isGrey) changeGreyOrWeak(true, "grey");
    if (themeConfig.value.isWeak) changeGreyOrWeak(true, "weak");
    if (themeConfig.value.autofit) changeAutofit(true, true);
  };
  // 切换暗黑模式
  const switchDark = () => {
    toggle(themeConfig.value.isDark);
  };
  //切换主题
  const toggle = (currentTheme: string) => {
    changeTheme(currentTheme);
    //自定义监听事件,反应theme的变化
    let newSetItemEvent = new Event("newSetItemEvent");
    window.dispatchEvent(newSetItemEvent);
  };
  const changeTheme = (val: string) => {
    document.documentElement.setAttribute("theme", val);
    document.querySelector("html")!.setAttribute("class", "");
    document.querySelector("html")?.setAttribute("class", val);
  };
  return {
    initTheme,
    switchDark,
    changeColor,
    changeGreyOrWeak,
    changeAutofit,
  };
};
