import { App as AppArg, createApp } from "vue";
import App from "./App.vue";
import ElementPlus, { ElMessage } from "element-plus";
import "element-plus/theme-chalk/index.css";
import "vxe-table/lib/style.css";
import "vxe-pc-ui/lib/style.css";
import "./assets/iconfont/iconfont.css";
import "./styles/index.scss";

import router from "./router/index";
// pinia store
import pinia from "./stores/index";
import TySVG from "./components/TySVG/index.vue";
//vxe-table
import VXETable from "vxe-table";
import VxeUI from "vxe-pc-ui";
import "./config/tableConfig";
// directives
import initDirectives from "./directives";
//i18n
import i18n from "./languages";
//指令库
import LeoDirectives from "@ileostar/v3-directives";
//导入主题
import * as echarts from "echarts";
import TyEchartDark from "./styles/plugs/chart/ty-dark-chart.json";
import TyEchartLight from "./styles/plugs/chart/ty-light-chart.json";
echarts.registerTheme("dark", TyEchartDark);
echarts.registerTheme("light", TyEchartLight);

const app = createApp(App);
initDirectives(app);
app.config.globalProperties.$message = ElMessage;
app
  .use(router)
  .use(pinia)
  .use(VXETable)
  .use(VxeUI)
  .use(i18n)
  .use(LeoDirectives)
  .component("TySVG", TySVG)
  .mount("#app");
