/**
 * 常量枚举
 */


// 摄像机在线离线
export enum CameraStateEnum {
  ONLINE = "1",
  OFFLINE = "0"
}

// 轮询组循环播放
export enum PollLoopPlayBack {
  LOOP = 1,   // 循环
  NONLOOP = 0,  // 不循环
}

export enum IsFullEnum{
  "全屏" = "0",
  "不全屏" = "1",
}

export enum StateEnum{
  "启用" = "0",
  "停用" = "1",
}

