/**
 * 初始化颜色配置,需要使用javascript操作，没特殊情况下谨慎使用
 *
 * **/
export const DEFAULT_ThEME: { [name: string]: string } = {
  primary: "#0085ff",
  // error: "#f56c6c",
  // danger: "#f15151",
  // success: "#19c199",
  // warning: "#f5a738",
  // info: "#909399",
};

/**
 * @name 可用于图表颜色
 *
 * **/
export default ["#00edff", "#8A31FB", "#FF7F58", "#FFE700", "#14C9C9", "#826AF9", "#165DFF"];
