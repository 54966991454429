import { defineStore } from "pinia";
import { AuthState } from "../../stores/interfaces";
import { getAllBreadcrumbList, getFlatArr, handleMenuList } from "../../utils/utils";
import { getAuthMenuListApi, getAuthMenuListLocal } from "@/api/modules/login";
import { ROUTER_HIDDEN } from "@/config/config";

// AuthStore
export const AuthStore = defineStore({
  id: "AuthState",
  state: (): AuthState => ({
    // 当前页面的 router name，用来做按钮权限筛选
    routeName: "",
    // 按钮权限列表
    authButtonList: [],
    // 菜单权限列表
    authMenuList: [],
    //本地路由
    localMenuListGet: [],
  }),
  getters: {
    // 按钮权限列表
    authButtonListGet: (state) => state.authButtonList,
    // 后端返回的菜单列表 ==> 这里没有经过任何处理
    authMenuListGet: (state) => state.authMenuList,
    // 后端返回的菜单列表 ==> 左侧菜单栏渲染，备用暂且不做处理
    showMenuListGet: (state) => state.authMenuList,
    // 扁平化之后的一维数组路由，主要用来添加动态路由
    flatMenuListGet: (state) => getFlatArr(state.authMenuList),
    // 所有面包屑导航列表
    breadcrumbListGet: (state) => getAllBreadcrumbList(state.authMenuList),
  },
  actions: {
    // 异步获取按钮权限
    async getAuthButtonList(data: Array<string>) {
      this.authButtonList = data;
    },
    // 异步获取路由
    async getAuthMenuList() {
      //服务端路由
      const { data: asyncData } = await getAuthMenuListApi();
      //本地路由
      const { data } = await getAuthMenuListLocal();
      // 由于服务端数据没有direction重定向，多做一层处理
      this.authMenuList = handleMenuList(ROUTER_HIDDEN ? asyncData : data);
      this.localMenuListGet = data;
    },
    // 设置路由名称
    async setRouteName(name: string) {
      this.routeName = name;
    },
  },
});
