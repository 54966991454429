/*
 * @Author: xiongyi xiongyi@teamway.org.cn
 * @Date: 2024-08-09 14:24:07
 * @LastEditors: xiongyi xiongyi@teamway.org.cn
 * @LastEditTime: 2024-08-17 18:07:07
 * @FilePath: \business-web\src\directives\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { App, Directive, VNode } from "vue";
import * as focus from "./modules/focus";
import * as empty from "./modules/empty";
import * as auth from "./modules/auth";
import * as alg from "./modules/alg";

interface directivesProps {
  [key: string]: Directive;
}

let directives: directivesProps = {
  ...focus,
  ...empty,
  ...auth,
  ...alg,
};

export default function initDirectives(app: App) {
  console.log(directives);

  Object.keys(directives).forEach((d) => {
    app.directive(d, directives[d]);
  });
}
