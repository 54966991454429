 // * 后端微服务端口名
export const PORT1 = "/api"; //用户权限
export const PORT2 = "/api/business-server"; //基础服务
export const PORT3 = "/api/GBVideoSignalServer"; //视频服务
export const PORT4 = "/api/business-server"; //巡检
export const PORT5 = "/api/resource"; //服务器资源
export const PORT6 = "/api/oss-server"; //储存对象
export const PORT7 = "/api/ObjectDetect"; //目标识别推流


// // * 后端微服务端口名
// export const PORT1 = "/base/perms"; //用户权限
// export const PORT2 = "/base/base/baseService-server"; //基础服务
// export const PORT3 = "/base/cpi"; //视频服务
// export const PORT4 = "/base/base/baseService-server"; //巡检
// export const PORT5 = "/base/resource";
