import router from "@/router/index";
import { isType } from "@/utils/utils";
import { HOME_URL, LOGIN_URL, TOKEN_EXCEED } from "@/config/config";
import { ElNotification } from "element-plus";
import { GlobalStore } from "@/stores";
import { AuthStore } from "@/stores/modules/auth";
import { IsFullEnum } from "@/enums";

// 引入 views 文件夹下所有 vue 文件
const modules = import.meta.glob("@/views/**/*.vue");
/**
 * 初始化动态路由
 */
export const initDynamicRouter = async () => {
  const authStore = AuthStore();
  const globalStore = GlobalStore();
  try {
    // 1.获取菜单列表 && 按钮权限（可合并到一个接口获取，根据后端不同可自行改造）
    await authStore.getAuthMenuList(); //菜单
    await globalStore.setUserInfo(); //用户信息

    await authStore.getAuthButtonList(globalStore.userInfo?.permissions || []);

    // 2.判断当前用户有没有菜单权限
    if (!authStore.authMenuListGet.length) {
      ElNotification({
        title: "无权限访问",
        message: "当前账号无任何菜单权限，请联系系统管理员！",
        type: "warning",
        duration: 3000,
      });
      globalStore.setToken("");
      router.replace(TOKEN_EXCEED);
      return Promise.reject("No permission");
    }

    // 3.添加动态路由
    authStore.flatMenuListGet.forEach(async(item: any) => {
      item.children && delete item.children;
      if (item.component && isType(item.component) == "string") {
        item.component = await modules["/src/views" + item.component + ".vue"];
      }
      if (item?.isFull === IsFullEnum.全屏) {
        router.addRoute(item);
      } else {
        router.addRoute("layout", item);
      }
    });
    //没有默认重定向，采用动态路由首位
    if (!HOME_URL) {
      router.addRoute({
        path: "/",
        redirect: authStore.flatMenuListGet[0]["path"],
      });
    }
  } catch (error) {
    // 💢 当按钮 || 菜单请求出错时，重定向到登陆页
    globalStore.setToken("");
    router.replace(LOGIN_URL);
    return Promise.reject(error);
  }
};
