export default {
  commons: {
    add: "新增",
    del: "删除",
    update: "编辑",
    query: "查询",
    camera: "摄像机",
    type: "类型",
    name: "名称",
    batch: "批量",
    state: "状态",
    system: "配置",
    downp: "下载",
    import: "导入",
    export: "导出",
    upload: "上传",
    loading: "刷新",
    reset: "重置",
    expand: "展开",
    full: "全屏",
    view: "查看",
    on: "开",
    off: "关",
    region: "区域",
  },
  baseCamera: {
    name: "name",
    type: "",
    regionId: "区域",
    regionName: "区域",
    username: "用户名",
    password: "密码",
    ip: "ip地址",
    port: "端口",
    channel: "通道号",
    feature: "摄像机功能",
    videoProtocol: "视频协议",
    factory: "厂家",
    mainId: "主ID",
    subId: "子ID",
    state: "状态",
    videoUrl: "string",
    playModel: "电视墙播放方式",
    playPort: "播放端口",
    offLineTime: "在线",
  },
  tabs: {
		more: "更多",
		refresh: "刷新",
		maximize: "最大化",
		closeCurrent: "关闭当前",
		closeOther: "关闭其它",
		closeAll: "关闭所有"
	},
};
