import { PORT1, PORT2 } from "../config/servicePort";
import http from "../index";
import { Dept, User, Menu, Post, Role, LoginInfo, OPerlog, ServiceInfo } from "../interface/user";
import { ResPage, ResultData, Result } from "../interface";
import qs from "qs";
import { Login } from "../interface/login";
const notify = {
  notifyWhenFailure: true,
  notifyWhenSuccess: true,
};
/**
 * @name 用户管理模块
 * **/

//* 获取用户信息
export const getUserInfo = () => {
  return http.get<ResultData<User.ResultUserData>>(PORT1 + `/getInfo`);
};

// 查询用户列表
export const listUser = (params: User.ReqUserParams) => {
  return http.get<ResPage<User.ResUserList>>(PORT1 + `/system/user/list`, params);
};

// 查询用户详细
export const getUser = (userId?: string) => {
  return http.get<ResPage<User.ResUserList>>(PORT1 + `/system/user/${userId}`);
};

// 新增用户
export const addUser = (params: User.ReqUserParams) => {
  return http.post<ResPage<User.ResUserList>>(PORT1 + `/system/user`, params, notify);
};

// 修改用户
export const updateUser = (params: User.ReqUserParams) => {
  return http.put<ResPage<User.ResUserList>>(PORT1 + `/system/user`, params, notify);
};

// 删除用户
export const delUser = (userId: string) => {
  return http.delete<Result>(PORT1 + `/system/user/${userId}`, {}, notify);
};

// 用户密码重置
export const resetUserPwd = (params: { userId: any; password: any }) => {
  return http.put<ResPage<User.ResUserList>>(PORT1 + `/system/user/resetPwd`, params, notify);
};

// 修改密码
export const updatePwd = (params: { oldPassword: any; newPassword: any }) => {
  let url = `/system/user/profile/updatePwd`;
  return http.put<ResPage<User.ResUserList>>(PORT1 + url, params, notify);
};
// 用户状态修改
export const changeUserStatus = (params: User.StatuPrams) => {
  return http.put<Result>(PORT1 + `/system/user/changeStatus`, params, notify);
};

// 用户头像上传
export const uploadAvatar = (params: User.StatuPrams) => {
  return http.post<ResPage<User.ResUserList>>(PORT1 + `/system/user/profile/avatar`, params);
};
// 查询授权角色
export const getAuthRole = (userId: string) => {
  return http.get<ResPage<User.ResUserList>>(PORT1 + `/system/user/authRole/`, { userId });
};

// 保存授权角色
export const updateAuthRole = (params: User.StatuPrams) => {
  return http.put<ResPage<User.ResUserList>>(PORT1 + `/system/user/authRole`, params);
};
// 查询部门下拉树结构
export const deptTreeSelect = () => {
  return http.get<ResPage<User.ResUserList>>(PORT1 + `/system/user/deptTree`);
};

// 查询用户个人信息
export const getUserProfile = () => {
  return http.get<ResPage<User.ResUserList>>(PORT1 + `/system/user/profile`);
};
// 查询api列表
export const getApiList = () => {
  return http.get<ResPage<User.ApiList>>(PORT2 + `/getApiList`);
};

/**
 * @name 部门管理模块
 * **/

// 查询部门列表
export const listDept = (params: Partial<Dept.ReqDeptParams>) => {
  return http.get<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept/list`, params);
};

// 查询部门列表（排除节点）
export const listDeptExcludeChild = (deptId: string) => {
  return http.get<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept/list/${deptId}`);
};

// 查询部门详细
export const getDept = (deptId: string) => {
  return http.get<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept/${deptId}`);
};

// 新增部门
export const addDept = (params: Dept.ReqDeptParams) => {
  return http.post<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept`, params, notify);
};

// 修改部门
export const updateDept = (params: Dept.ReqDeptParams) => {
  return http.put<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept`, params, notify);
};

export const delDept = (deptId: number) => {
  return http.delete<ResPage<Dept.ResDeptList>>(PORT1 + `/system/dept//${deptId}`, {}, notify);
};

/**
 *@name 菜单管理模块
 * **/
// 查询菜单列表
export const listMenu = (params: Menu.ReqMenuParams) => {
  return http.get<ResPage<Menu.ResMenuList>>(PORT1 + `/system/menu/list`, params);
};

// 查询菜单下拉树结构
export const treeselect = (isSuccess: boolean = false) => {
  return http.get<ResultData<Menu.ResMenuTree>>(
    PORT1 + `/system/menu/treeselect`,
    {},
    { notifyWhenSuccess: isSuccess },
  );
};

// 根据角色ID查询菜单下拉树结构
export const roleMenuTreeselect = (menuId: string) => {
  return http.get<ResultData<Menu.ResMenuTree>>(
    PORT1 + `/system/menu/roleMenuTreeselect/${menuId}`,
    {},
    { loading: true },
  );
};

// 查询菜单详细
export const getMenu = (menuId: number) => {
  return http.get<ResultData<Menu.ResMenuList>>(PORT1 + `/system/menu/${menuId}`);
};

// 新增菜单
export const addMenu = (params: Menu.ReqMenuParams) => {
  return http.post<ResultData<Menu.ResMenuList>>(PORT1 + `/system/menu`, params, notify);
};

// 修改菜单
export const updateMenu = (params: Menu.ReqMenuParams) => {
  return http.put<ResultData<Menu.ResMenuList>>(PORT1 + `/system/menu`, params, notify);
};

export const delMenu = (menuId: number) => {
  return http.delete<Result>(PORT1 + `/system/menu/${menuId}`, {}, notify);
};

/**
 * @name 岗位模块
 */

// 查询岗位列表
export const listPost = (params: Post.ReqPostParams) => {
  return http.get<ResPage<Post.ResPostList>>(PORT1 + `/system/post/list`, params);
};

// 查询岗位详细
export const getPost = (postId: Post.ReqPostParams) => {
  return http.get<ResPage<Post.ResPostList>>(PORT1 + `/system/post`, postId);
};

// 新增岗位
export const addPost = (params: Post.ReqPostParams) => {
  return http.post<ResPage<Post.ResPostList>>(PORT1 + `/system/post`, params, notify);
};

// 修改岗位
export const updatePost = (params: Post.ReqPostParams) => {
  return http.put<ResPage<Post.ResPostList>>(PORT1 + `/system/post`, params, notify);
};

// 删除岗位
export const delPost = (postId: string) => {
  return http.delete<ResPage<Post.ResPostList>>(PORT1 + `/system/post/${postId}`, {}, notify);
};

/**
 * @name 角色模块
 */

// 查询角色列表
export const listRole = (params: Role.ReqRoleParams) => {
  return http.get<ResPage<Role.ReqRoleParams>>(PORT1 + `/system/role/list`, params);
};

// 查询角色详细
export const getRole = (roleId: Role.ReqRoleParams) => {
  return http.get<ResPage<Role.ReqRoleParams>>(PORT1 + `/system/role`, roleId);
};

// 新增角色
export const addRole = (params: Role.ReqRoleParams) => {
  return http.post<ResPage<Role.ResRoleList>>(PORT1 + `/system/role`, params, notify);
};

// 修改角色
export const updateRole = (params: Role.ResRoleList) => {
  return http.put<ResPage<Role.ResRoleList>>(PORT1 + `/system/role`, params, notify);
};

// 删除角色
export const delRole = (roleId: string) => {
  return http.delete<ResPage<Role.ResRoleList>>(PORT1 + `/system/role/${roleId}`, {}, notify);
};

/**
 * @name 登录日志管理模块
 */
// * 获取日志
export const getLoginInfo = (params: LoginInfo.ReqLoginInfoParams) => {
  return http.get<ResPage<LoginInfo.ResLoginInfoList>>(PORT1 + `/monitor/logininfor/list`, params);
};

// * 删除日志
export const delLogInfo = (params: { infoIds: string[] }) => {
  let data = params.infoIds.length > 1 ? params.infoIds.join(",") : params.infoIds[0];
  return http.delete<Result>(PORT1 + `/monitor/logininfor/${data}`, {}, notify);
};

/**
 * @name 操作日志管理模块
 */
// * 获取日志
export const getOperlog = (params: OPerlog.ReqOPerlogParams) => {
  return http.get<ResPage<OPerlog.ResOPerlogList>>(PORT1 + `/monitor/operateLog/list`, params);
};

// * 删除日志
export const delOperlog = (params: { infoIds: string[] }) => {
  let data = params.infoIds.length > 1 ? params.infoIds.join(",") : params.infoIds[0];
  return http.delete<Result>(PORT1 + `/monitor/operateLog/${data}`, {}, notify);
};

/**
 * @name 服务信息接入
 * **/

// 查询服务列表
export const getServiceAll = (params: ServiceInfo.ReqServiceInfoParams) => {
  return http.get<ResPage<ServiceInfo.ResServiceInfoList>>(PORT1 + `/router/list`, params);
};

// 新增服务
export const addService = (params: Role.ReqRoleParams) => {
  return http.post<Result>(PORT1 + `/router`, params, notify);
};

// 修改服务
export const updateService = (params: Role.ReqRoleParams) => {
  return http.put<Result>(PORT1 + `/router`, params, notify);
};

// 删除服务
export const delService = (routeId: string) => {
  return http.delete<Result>(PORT1 + `/router/${routeId}`, {}, notify);
};
