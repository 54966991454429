import mqtt from "mqtt";
import { Options } from "./interfaces";

export class Client {
  public client: mqtt.MqttClient;
  private static instance: Client | null = null;
  private constructor(options: Options) {
    this.client = mqtt.connect(options.url, {
      username: options.option.userName,
      password: options.option.password,
      keepalive: options.option.keepAliveInterval, // seconds, set to 0 to disable
      connectTimeout: options.option.timeout * 1000, //milliseconds, time to wait before a CONNACK is received
      clientId: `xby${Math.round(Math.random() * 800 + 100)}`,
    });
    this.client.on("connect", () => {
      let { theme, qos } = options;
      for (let i = 0; i < theme.length; i++) {
        this.client.subscribe(theme[i], {
          qos: qos,
        });
      }
    });
  }
  // 静态方法，用于获取实例
  public static getInstance(options: Options): Client {
    if (!Client.instance) {
      Client.instance = new Client(options);
    }
    return Client.instance;
  }
}
