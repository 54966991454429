import { defineStore, createPinia } from "pinia";
import { GlobalState, ThemeConfigProps } from "./interfaces";
import { DEFAULT_ThEME } from "../config/colors";
import piniaPersistConfig from "@/config/piniaPersist";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { getUserInfo } from "../api/modules/user";
import { listInformation } from "@/api/modules/base";

export const GlobalStore = defineStore({
  id: "GlobalStore",
  state: (): GlobalState => ({
    token: "",
    userInfo: null,
    themeConfig: {
      // 当前页面是否全屏
      maximize: false,
      // 布局切换 ==>  管理系统模式：simplicity  科技导航栏模式 transverse
      layout: "transverse",
      // 默认 primary 主题颜色
      primary: DEFAULT_ThEME.primary,
      // 深色模式
      isDark: "ty-dark",
      // 灰色模式
      isGrey: false,
      // 色弱模式
      isSunique: true,
      //菜单手风琴
      isWeak: false,
      // 折叠菜单
      isCollapse: false,
      // 面包屑导航
      breadcrumb: true,
      // 面包屑导航图标
      breadcrumbIcon: true,
      // 标签页
      tabs: true,
      // 标签页图标
      tabsIcon: true,
      // 页脚
      footer: true,
      //等比缩放
      autofit: false,
      // 导航栏样式
      nav: 2,
      // 模块样式
      navItem: 2,
    },
    sysConfig: null,
    loginConfig: {
      storagePass: false,
    },
  }),
  getters: {},
  actions: {
    async setToken(token: string) {
      this.token = token;
    },
    //异步获取用户信息
    async setUserInfo() {
      //特殊返回结构
      const { data } = await getUserInfo();
      this.userInfo = data;
    },
    async setSysConfig() {
      const { data } = await listInformation();
      this.sysConfig = data;
    },
    setThemeConfig(themeConfig: ThemeConfigProps) {
      this.themeConfig = themeConfig;
    },
  },
  persist: piniaPersistConfig("GlobalState"),
});
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); //数据持久化插件,默认loacalstorage

export default pinia;
