/**
 * v-auth
 * 按钮权限指令
 */
import { AuthStore } from "@/stores/modules/auth";
import { useRoute } from "vue-router";
import { h, type Directive, type DirectiveBinding } from "vue";
import {View} from '@element-plus/icons-vue'
import TySVG from '@/components/TySVG/index.vue'

export const auth: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding,vnode) {
    const { value } = binding;
    //所有权
    const all_permission = "*:*:*";
    const authStore = AuthStore();

    const currentPageRoles = authStore.authButtonListGet;
    if (!currentPageRoles.includes(value) && !currentPageRoles.includes(all_permission)) {
      el.remove();
    }
    // if (value instanceof Array && value.length) {
    //   const hasPermission =
    //     value.every((item) => currentPageRoles.includes(item)) &&
    //     !currentPageRoles.includes(all_permission);
    //   if (!hasPermission) el.remove();
    // } else {
    //   if (!currentPageRoles.includes(value) && !currentPageRoles.includes(all_permission))
    //     el.remove();
    // }
  },
};
