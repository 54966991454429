import axios from "axios";
import { ElMessage } from "element-plus";
import { GlobalStore } from "@/stores";
// import { ElNotification } from 'element-plus'
const instance = axios.create();
instance.interceptors.request.use((config: any) => {
  const GlobalState = GlobalStore();
  config.headers.Authorization = GlobalState.token;
  return config;
});
instance.interceptors.response.use((response: any) => {
  const host = window.location.hostname;
  if (response.data.code == 1308) {
    const url =
      import.meta.env.MODE == "production"
        ? `http://${host}/#/login/?setting=${1}`
        : `http://${host}:5555/#/login/?setting=${1}`;
    window.location.assign(url);
  }
  return response;
});
export const exportAxios = (url: string, data = {}, cb?: () => void, method = "post") => {
  // const msg: any = ElNotification({
  //   title: '提示',
  //   dangerouslyUseHTMLString: true,
  //   message: `<div> <span class="el-icon-loading"></span>&ensp;数据导出中...</div>`,
  //   type: 'warning',
  //   duration: 0,
  //   customClass: 'atlas'
  // })
  if (method == "get") {
    instance
      .get(url, {
        responseType: "blob",
        onDownloadProgress(progress) {
          console.log(progress);
        },
      })
      .then((res: any) => {
        if (res.data.type && res.data.type === "application/json") {
          const reader: any = new FileReader(); // 创建读取文件对象
          reader.readAsText(res.data, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          reader.addEventListener("loadend", function () {
            const res = JSON.parse(reader.result); // 返回的数据
            if (res && res.code !== 0 && res.code !== 200) {
              ElMessage.error(res.message);
            } else {
              ElMessage.success(res.message);
              cb && cb();
            }
          });
        } else {

          cb && ElMessage.error("导入失败");
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });

          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); // 创建下载的链接
          downloadElement.href = href;
          const name = res.headers["content-disposition"].split("''")[1];
          downloadElement.download = revertUTF8(name); // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
          // msg.close()
        }
      })
      .catch((error) => {
        // msg.close()
        // ElNotification({
        //   title: '提示',
        //   dangerouslyUseHTMLString: true,
        //   message: `<div>${name}导出失败！</div>`,
        //   type: 'warning',
        //   duration: 3000,
        //   customClass: 'atlas'
        // })
        // ElMessage({ type: 'error', message: "请求错误！" });
      });
  } else {
    instance
      .post(url, data, {
        responseType: "blob",
        // headers:{"Content-Type":'application/octet-stream'},
        onDownloadProgress(progress) {
          console.log(progress);
        },
      })
      .then((res: any) => {
        if (res.data.type && res.data.type === "application/json") {
          const reader: any = new FileReader(); // 创建读取文件对象
          reader.readAsText(res.data, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          reader.addEventListener("loadend", function () {
            const res = JSON.parse(reader.result); // 返回的数据
            if (res && res.code !== 0 && res.code !== 200) {
              ElMessage.error(res.message ? res.message : res.msg);
            } else {
              ElMessage.success(res.message ? res.message : res.msg);
              cb && cb();
            }
          });
        } else {
          cb && ElMessage.error("导入失败");
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); // 创建下载的链接
          downloadElement.href = href;
          const name = res.headers["content-disposition"].split("''")[1];
          downloadElement.download = revertUTF8(name); // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
          // msg.close()
        }
      })
      .catch((error) => {
        // msg.close()
        // ElNotification({
        //   title: '提示',
        //   dangerouslyUseHTMLString: true,
        //   message: `<div>${name}导出失败！</div>`,
        //   type: 'warning',
        //   duration: 3000,
        //   customClass: 'atlas'
        // })
        // ElMessage({ type: 'error', message: "请求错误！" });
      });
  }
};
export default exportAxios;

function revertUTF8(szInput: string) {
  let x,
    wch,
    wch1,
    wch2,
    uch = "",
    szRet = "";
  for (x = 0; x < szInput.length; x++) {
    if (szInput.charAt(x) == "%") {
      wch = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16);
      if (!wch) {
        break;
      }
      if (!(wch & 0x80)) {
        wch = wch;
      } else if (!(wch & 0x20)) {
        x++;
        wch1 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16);
        wch = (wch & 0x1f) << 6;
        wch1 = wch1 & 0x3f;
        wch = wch + wch1;
      } else {
        x++;
        wch1 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16);
        x++;
        wch2 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16);
        wch = (wch & 0x0f) << 12;
        wch1 = (wch1 & 0x3f) << 6;
        wch2 = wch2 & 0x3f;
        wch = wch + wch1 + wch2;
      }
      szRet += String.fromCharCode(wch);
    } else {
      szRet += szInput.charAt(x);
    }
  }
  return szRet;
}
