import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { ResultEnum } from "../enums/httpEnum";
import { CustomAxiosRequestConfig, RequestConfig, ResPage, ResultData } from "./interface";
import { GlobalStore } from "@/stores";
import { LOGIN_URL } from "@/config/config";
import notify from "./notify";
import { showFullScreenLoading, tryHideFullScreenLoading } from "@/config/serviceLoading";
import { AxiosCanceler } from "./cancel";
import router from "@/router";
//临时token，开发使用
// export const CURRENT_TOKEN = (url: string, token: string) => {
//   return token;
//   let isUrl = url.includes("/user/");
//   if (!isUrl && import.meta.env.DEV)
//     return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhZG1pbiIsImV4cCI6MTcxMjAyNDc3MywiaWF0IjoxNzExNDE5OTczLCJhY2NvdW50IjoiYWRtaW4iLCJ1c2VybmFtZSI6ImFkbWluIn0.deN-Ua8cThoZBEZUmc4QzGXG91DfcLNRAV_avgbRHc4";
//   else return token;
// };

const axiosCanceler = new AxiosCanceler();

const config = {
  //默认地址
  // baseURL: import.meta.env.VITE_API_URL,
  //请求超时
  timeout: ResultEnum.TIMEOUT as number,
  //跨域允许携带凭证
  withCredentials: true,
};

class RequestHttp {
  service: AxiosInstance;
  public constructor(config: AxiosRequestConfig) {
    this.service = axios.create(config);
    /**
     * @description 请求拦截器
     * **/
    this.service.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        // 重复请求不需要取消，在 api 服务中通过指定的第三个参数: { cancel: false } 来控制
        config.cancel && axiosCanceler.addPending(config);
        //全局加载，默认关闭
        config.loading && showFullScreenLoading();
        //全局设置token
        const globalStore = GlobalStore();
        const token = globalStore.token;
        config.headers.set("Authorization", token);
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );
    /**
     * @description 响应拦截_全局消息拦截
     * **/
    this.service.interceptors.response.use(notify.response.onFulfilled, notify.response.onRejected);
    /**
     * @description 响应拦截
     * **/
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data, config } = response;
        const globalStore = GlobalStore();
        axiosCanceler.removePending(config);
        if (data.code == ResultEnum.OVERDUE) {
          globalStore.setToken("");
          router.replace(LOGIN_URL);
        }
        tryHideFullScreenLoading();
        return data;
      },
      (error: AxiosError) => {
        tryHideFullScreenLoading();
        return Promise.reject(error);
      },
    );
  }

  //常用请求方法封装
  get<T>(url: string, params?: object, _object: RequestConfig = {}): Promise<T> {
    return this.service.get(url, { params, ..._object });
  }
  post<T>(url: string, params?: object, _object: RequestConfig = {}): Promise<T> {
    return this.service.post(url, params, _object);
  }
  put<T>(url: string, params?: object, _object: RequestConfig = {}): Promise<T> {
    return this.service.put(url, params, _object);
  }
  delete<T>(url: string, params?: object, _object: RequestConfig = {}): Promise<T> {
    return this.service.delete(url, { params, ..._object });
  }
  download(url: string, params?: object, _object: RequestConfig = {}): Promise<BlobPart> {
    return this.service.post(url, params, { ..._object, responseType: "blob" });
  }
  request<T>(RequestConfig: RequestConfig): Promise<T> {
    return this.service.request(RequestConfig);
  }
}

export default new RequestHttp(config);
